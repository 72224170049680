import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { lStorage } from "../../../components/utils/storage";
import View from "../../../assets/images/successfulcards/view-icon.svg";
import AdminService from "../../../components/services/admin.service";
import "../../../assets/styles/admin/datatable.css";
import backn from "../../../assets/images/backIconSummary.svg";
import { navigate } from "gatsby";

const ApproveCompanyTable = () => {
  const companyCode = lStorage.get("setApprovalCompany");
  const [customers, setCustomers] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [participantData, setparticipantData] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    contact_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    gender: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    email_address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    payment_date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const adminServices = new AdminService();

  const handleClose = () => {
    setOpenModal(false);
  };

  const callConfirmApproval = (id) => {
    adminServices
      .confirmParticipant(id)
      .then((res) => {
        if (res.data.isError === false || res.data.statusCode === "200") {
          toast.success(res?.data?.message);
          lStorage.remove("setApprovalCompany");
        } else {
          if (res.data.isError === true) {
            toast.error(res?.data?.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getCompanyParticipant = () => {
    adminServices.viewParticipant(companyCode?.company_code).then((data) => {
      if (data.data.isError === false || data.data.statusCode === "200") {
        setCustomers(data?.data?.data);
      } else {
        if (data.data.isError === true) {
          toast.error(data?.data?.message);
        }
      }
    });
  };

  useEffect(() => {
    getCompanyParticipant();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>

          <span>
          <span className="px-4 mb-2">
            <button
              class="btn btn-primary"
              onClick={() => {
                navigate("/admin/Company-registration/");
              }}
            >
              <img src={backn} alt="" className="px-2"/>
              Back
            </button>
          </span>
            <button
              class="btn btn-primary"
              onClick={() => {
                callConfirmApproval(companyCode?.company_code);
              }}
            >
              Approve
            </button>
          </span>
        </div>
      </>
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    if (rowData?.expiry_date !== null) {
      return moment(rowData?.expiry_date).format("DD-MM-YYYY HH:MM A");
    }
  };

  const viewData = (data) => {
    setOpenModal(true);
    setparticipantData(data);
  };

  const buttonTemplate = (data) => (
    <span
      type="button"
      role="button"
      tabIndex="0"
      className="ml-1"
      onKeyDown={() => viewData(data)}
      onClick={() => viewData(data)}
      title="View"
    >
      <img src={View} alt="" />
    </span>
  );

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <h3 className="py-3 px-2">
        <b>Participant Details</b>
      </h3>
      <div className="card">
        <DataTable
          value={customers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={15}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[15, 30, 50]}
          dataKey="id"
          rowHover
          selection={selectedCustomers}
          onSelectionChange={(e) => setSelectedCustomers(e.value)}
          filters={filters}
          filterDisplay="menu"
          // loading={loading}
          responsiveLayout="scroll"
          stripedRows
          size="small"
          emptyMessage="No Records found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="first_name"
            header="Name"
            sortable
            style={{ width: "10rem" }}
            filter
            filterPlaceholder="Search by Name"
          />
          <Column
            field="email_address"
            header="Email"
            sortable
            style={{ width: "15rem" }}
            filter
            filterPlaceholder="Search by Email"
          />
          <Column
            field="gender"
            header="Gender"
            sortable
            style={{ width: "15rem" }}
            filter
            filterPlaceholder="Search by PNR Number"
          />

          <Column
            field="contact_number"
            header="Contact Number"
            sortable
            filter
            style={{ width: "15rem" }}
          />
          <Column
            field="status"
            header="Status"
            sortable
            filter
            style={{ width: "15rem" }}
          />
          <Column
            field="payment_date"
            header="Date"
            sortable
            filterField="payment_date"
            dataType="date"
            style={{ minWidth: "15rem" }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          <Column
            header="Actions"
            headerStyle={{ width: "5rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={buttonTemplate}
          />
        </DataTable>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={openModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            Participant Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {participantData && (
            <table className="participant_table">
              <tbody>
                <tr className="table-row">
                  <td className="body-cell">Name</td>
                  <td className="body-cell">{participantData?.first_name}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Gender</td>
                  <td className="body-cell">{participantData?.gender}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Blood group</td>
                  <td className="body-cell">{participantData?.blood_group}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">DOB</td>
                  <td className="body-cell">
                    {moment(participantData && participantData?.dob).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">T-shirt Size</td>
                  <td className="body-cell">{participantData?.t_shirt_size}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Contact Number</td>
                  <td className="body-cell">
                    {participantData?.contact_number}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Email Id</td>
                  <td className="body-cell">
                    {participantData?.email_address}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Address</td>
                  <td className="body-cell">{participantData?.address}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Accomodation</td>
                  <td className="body-cell">
                    {participantData?.overnight_accommodation === "Y"
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">BIB collection location</td>
                  <td className="body-cell">
                    {participantData?.bib_collection_location}
                  </td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Race Price</td>
                  <td className="body-cell">₹ {participantData?.race_price}</td>
                </tr>
                <tr className="table-row">
                  <td className="body-cell">Company Code</td>
                  <td className="body-cell">{participantData?.company_code}</td>
                </tr>
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApproveCompanyTable;
